html {
  font-size: 14px;
}

body {
  margin: 0;
  background-color: #000;
  user-select: none;
}
* {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #1a1a1a;
  box-shadow: 0 0 15px rgba(0, 0, 0, 1), 0 0 0 1000px rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 15px;
  color: #fff;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  color: rgba(255, 255, 255, 0.2);
  font-size: 2rem;
  font-weight: 700;
  margin: 15px 0 0;
}

section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
}
section canvas {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 3px 3px 6px rgb(0 0 0 / 20%);
}
section span.cursor {
  color: rgba(255, 255, 255, 0.2);
}

.space {
  flex: 1;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
}
label {
  display: block;
  margin-bottom: 10px;
}
span {
  user-select: text !important;
}
input,
select {
  display: block;
  background-color: #fff;
  border: none;
  padding: 5px;
  border-radius: 5px;
  user-select: text !important;
}
input[type='submit'],
button,
a.button {
  border: none;
  border-radius: 5px;
  background-color: #0984e3;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;
  padding: 5px 10px;
}
.gray {
  background-color: #444 !important;
}

@keyframes twinkle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.is-loading {
  animation: twinkle 1s ease-in-out infinite;
}

.is-grabbing {
  cursor: grabbing;
}

.PrivateValueLabel-circle-4 {
  transform: translate(0, 70px) rotate(135deg) !important;
}
.PrivateValueLabel-label-5 {
  transform: rotate(225deg) !important;
}
